body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1b1b1b;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h4.MuiTypography-h4{
    color: #aa8cff;
    /* background: url('./../public/assets/line.svg') no-repeat; */
    margin-bottom: 10px;
    background-size: 300px;
    font-weight: 600;
    background-position: bottom 8px left;
    transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 1s 0s;
}

.about-me,.skills,.timeline,.certifications,.more-about-me,.carousel{
  margin:32px 0;
  padding: 16px 0;
  border-radius: 16px;
  color: #262c38;
}

.more-about-me{
  padding: 0;
}

.MuiAppBar-root{
    background-color: rgba(42, 42, 42, 0.05);
    opacity: 1;
    color: rgb(42, 42, 42);
    margin-top: 16px;
}

.about-me,.timeline{
  color: #e9ebf2;

}

.skills{
  background: #e9ebf2;
}
.certifications,.skills{
  background: #cbc9f3;
  padding: 16px;

}
.certifications h4,.skills h4{
  color: #262c38;
}

.certifications img {
  mix-blend-mode: darken;
  background: white;
}
