.cert-image{
    width: 180px;
    height: auto;
}
.cert-image.mct{
    width: 200px;
    height: auto;
}

@media (max-width: 768px) {
    .cert-image {
        width: 120px;
        height: auto;
    }
}
