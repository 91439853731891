.connet {
    font-weight: 600;
    background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1f1f1f;
    text-align: center;
}

.connect-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 96px
}

@media (max-width: 768px) {
    .footer {
        position: relative;
    }
    .connet-text{
        font-size: 13px;
    }
    .connect-section {
        /* display: grid; */
    }
}