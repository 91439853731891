.about-me {
    margin: 16px 0;
    padding: 32px 16px;
    min-height: 220px;
}
.about-me .highlight{
    font-weight: 700;
    padding: 0 2px;
    color: #fff;
}

.certifications .certificateTitle{
    font-size: 14px;
    font-weight: normal;
    margin: 8px 0;
}

.about-me-image{
    border-top-left-radius: 88px;
    /* background: #e3ded0; */
    margin: 32px 16px ;
    height: 100%;
}

.image{
    height: 100%;
    height: 60vh;
    /* margin: 10px; */
    background: url(../../../public/assets/images/personal/main.png) no-repeat center center;
    background-size: contain;
}
.image img{
    height: 100%;
    width: 100%;
    border-radius: 8px;
    mix-blend-mode: darken;
}


@media (max-width: 767px) {
    .image{
        height: 36vh;
    }
}