.header{

}

.header .title{
    margin: 0;
}

.header .subtitle{
    margin: 0;
}



.hoverEffect:hover {
    transform: scale(1.07);
    transition: transform 0.3s ease-in-out;
    background-color: rgba(255,255,255,.2) !important;
}